@font-face {
  font-family: 'Inter';
  src: url('../fonts/Inter-Regular.woff2') format('woff2'),
       url('../fonts/Inter-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('../fonts/Inter-Medium.woff2') format('woff2'),
       url('../fonts/Inter-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('../fonts/Inter-Black.woff2') format('woff2'),
       url('../fonts/Inter-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
}
.photo-gallery__like-btn {
    width: 22px;
    height: 19px;
    justify-self: flex-end;
    background-image: url('../../../images/like_disabled.svg');
    border: none;
    background-color: transparent;
    transition: 0.2s linear;
    cursor: pointer;
}

.photo-gallery__like-btn:hover {
    opacity: 0.5;
}
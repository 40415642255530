.popup__title {
    width: 330px;
    font-style: normal;
    font-weight: 900;
    font-size: 24px;
    line-height: 29px;
    color: #000000;
    margin: 34px 36px 54px;
    padding: 0;
}

@media screen and (max-width: 540px) {
    .popup__title {
        width: 238px;
        font-size: 18px;
        margin: 25px auto 75px;
    }
}
.popup__submit-btn {
    display: block;
    width: 358px;
    height: 50px;
    background: #000000;
    color: white;
    border-radius: 2px;
    border: 0;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    margin: 48px auto 37px;
    padding: 0;
    transition: opacity .2s linear;
    cursor: pointer;
}

.popup__submit-btn:hover {
    opacity: 0.8;
}

@media screen and (max-width: 540px) {
    .popup__submit-btn {
        width: 238px;
        height: 46px;
        margin: 45px auto 25px;
        font-size: 14px;
    }
}
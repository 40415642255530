.header {
    margin:  0 auto 40px;
    padding-top: 45px;
    max-width: 880px;
    border-bottom: 1px solid rgba(84, 84, 84, 0.7);
    display: flex;
    justify-content: space-between;
}

@media screen and (max-width: 1024px) {
    .header {
        padding-top: 32px;
    }
}

@media screen and (max-width: 540px) {
    .header {
        padding-top: 28px;
    }
}
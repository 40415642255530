.profile__inner-name {
    grid-area: name;
    margin: 0;
    font-size: 42px;
    font-weight: 500;
    line-height: 1;
    color: white;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 294px;
}

@media screen and (max-width: 910px) {
    .footer__inner-name {
        font-size: 34px;
    }
}

@media screen and (max-width: 540px) {
    .profile__inner-name {
        font-size: 27px;
        text-align: center;
    }
}
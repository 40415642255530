.profile__add-btn {
    width: 150px;
    height: 50px;
    margin-left: auto;
    background-color: transparent;
    padding: 0;
    background-image: url('../../../images/Add_Btn.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 22px 22px;
    border: 2px white solid;
    border-radius: 2px;
    transition: 0.2s linear;
    cursor: pointer;
}

.profile__add-btn:hover {
    opacity: 0.6;
}

@media screen and (max-width: 768px) {
    .profile__add-btn {
        width: 282px;
        margin: auto;
        background-size: 16px 16px;
    }
}
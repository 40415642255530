.profile__avatar {
    position: relative;
    width: 120px;
    height: 120px;
}

.profile__avatar:hover .profile__avatar-img {
    background-color: black;
    opacity: 0.8;
    cursor: pointer;
}

.profile__avatar:hover .profile__avatar-btn {
    opacity: 1;
    z-index: 1;
}
.popup__form {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 0;
    margin: 34px auto 45px;
    width: 358px;
    min-height: 260px;
    border-radius: 10px;
    font-family: 'Inter', sans-serif;
}

@media screen and (max-width: 540px) {
    .popup__form {
        width: 238px;
    }
}
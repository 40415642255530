.infoTooltip__text {
    color: black;
    font-size: 24px;
    font-weight: 900;
    text-align: center;
    width: 358px;
    margin: auto;
    padding-bottom: 60px;
}

@media screen and (max-width: 540px) {
    .infoTooltip__text {
        font-size: 20px;
        width: 244px;
    }
}
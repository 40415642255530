.popup__container {
    position: relative;
    display: flex;
    flex-direction: column;
    z-index: 1;
    width: 430px;
    background: #fff;
    margin: auto;
    box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
}

@media screen and (max-width: 540px) {
    .popup__container {
        width: 282px;
    }
}
.header__text {
    color: white;
    font-size: 18px;
    font-weight: 500;
    margin: 0 auto 46px 517px;
}

@media screen and (max-width: 900px) {
    .header__text {
        margin: 0 auto 46px;
        font-size: 14px;
    }
}
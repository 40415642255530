.popup {
    background-color: rgba(0,0,0,.5);
    position: fixed;
    width: 100vw;
    height: 100vh;
    display: flex;
    inset: 0;
    opacity: 0;
    visibility: hidden;
    transition: visibility 0.5s, opacity 0.5s linear;
}
.delete-popup__container {
    width: 430px;
    background-color: white;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    position: relative;
    margin: auto;
    color: black;
}

@media screen and (max-width: 540px) {
    .delete-popup__container {
        width: 282px;
    }
}
.authorize__form-btn {
    background-color: white;
    color: black;
    margin-top: 216px;
}

@media screen and (max-width: 540px) {
    .authorize__form-btn {
        font-size: 16px;
        margin-top: 173px;
    }
}
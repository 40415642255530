.footer__copyright {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: #545454;
    padding: 0;
    margin: 0;
}

@media screen and (max-width: 900px) {
    .footer__copyright {
        font-size: 16px;
        padding-left: 27px;
    }
}

@media screen and (max-width: 540px) {
    .footer__copyright {
        font-size: 14px;
        padding-left: 19px;
    }
}
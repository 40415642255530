.popup__input {
    display: block;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    width: 358px;
    height: 27px;
    padding: 0;
    margin: 0px auto 30px;
    border: none;
    border-bottom: 1px solid rgba(0, 0, 0, .2);
    cursor: pointer;
}

@media screen and (max-width: 540px) {
    .popup__input {
        width: 238px;
    }
}
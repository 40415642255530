.footer {
    max-width: 880px;
    margin: 66px auto 0px;
    padding-bottom: 60px;
}

@media screen and (max-width: 800px) {
    .footer {
        width: 90%;
        margin: 48px auto 0px;
        padding-bottom: 36px;
    }
}
